import React from "react";
import { Card, Col, Row, message } from "antd";
import { Button } from "react-bootstrap";

import moment from "moment";
import IdCard from "../IdCardComponent/IdCard";
import IdBack from "../IdCardComponent/IdBack";
import { useUpdateAppliedLeaveMutation } from "../../Slices/appliedLeavesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setVisible } from "../../Slices/visibleSlice";
import { useSendMailMutation } from "../../Slices/appliedLeavesApiSlice";
import { GetScreenSizes } from "../ScreenSizes";
const { Meta } = Card;

const DescriptionTable = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.visible.isVisible);
  const user = useSelector((state) => state.auth.userInfo.EMAIL);
  const [updateAppliedLeave] = useUpdateAppliedLeaveMutation();
  const [sendMail] = useSendMailMutation();
  const { isSmallScreen, isMediumScreen, isLargeScreen } = GetScreenSizes();

  if (props.val === "") {
    return <div>Click "View More" to see details</div>;
  } else {
    const x = props.data;
    async function handleClick(status) {
      props.updateDisplayInTable(x._id);
      dispatch(setVisible(false));
      try {
        await updateAppliedLeave({
          token: x.TOKEN,
          status: status,
          htno: x.HTNO,
          asn: x.ASN_DATE,
          reviewed_by: user,
        });
        await sendMail({
          type: status,
          desc: x.REASON,
          mail: props.studentEmail,
          from: x.FROM,
          to: x.TO,
          token: x.TOKEN,
          warden: x.REVIEWER,
        });

        status === "approved"
          ? message.success(`Leave approved for ${x.HTNO}`)
          : message.warning(`Leave rejected for ${x.HTNO}`);

        props.triggerFunction({
          htno: x.HTNO,
          asnDate: x.ASN_DATE,
          token: x.TOKEN,
        });
      } catch (error) {
        message.error("Error updating applied leave. Please try again.");
      }
    }

    function formatDateTime(dateTimeString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };

      const formattedDate = new Date(dateTimeString).toLocaleString(
        undefined,
        options
      );
      return formattedDate;
    }

    return (
      <>
        {isVisible && isLargeScreen && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div>
                <IdCard data={x} />
              </div>
              <div style={{ width: isSmallScreen ? "400px" : "500px" }}>
                <IdBack data={x} />
              </div>
            </div>

            <div>
              <div style={{ padding: "10px" }}>
                <Button
                  variant="outline-success"
                  onClick={() => handleClick("approved")}
                  style={{ marginLeft: "5px" }}
                >
                  Approve Leave
                </Button>

                <Button
                  variant="outline-danger"
                  onClick={() => handleClick("rejected")}
                >
                  Reject Leave
                </Button>
              </div>
            </div>
          </div>
        )}
        {isVisible && (isMediumScreen || isSmallScreen) && (
          <Row style={{ gap: "10px", marginBottom: "10px" }}>
            <Col xs={24} md={16}>
              <IdCard data={x} />
            </Col>
            <Col xs={24} md={16}>
              <IdBack data={x} />
            </Col>
            <Col xs={24} md={16}>
              <Button
                variant="outline-success"
                onClick={() => handleClick("approved")}
                style={{ marginLeft: "5px" }}
              >
                Approve Leave
              </Button>

              <Button
                variant="outline-danger"
                onClick={() => handleClick("rejected")}
              >
                Reject Leave
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
};

export default DescriptionTable;
