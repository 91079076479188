import React, { Suspense, lazy, useState } from "react";
import mu from "../Components/NavBar/logo.png";
import { Container, Navbar, Nav, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useFetchAppliedLeavesQuery } from "../Slices/appliedLeavesApiSlice";
import { useFetchStudentByIdQuery } from "../Slices/studentApiSlice";
import { logout } from "../Slices/authSlice";
import { setActiveKey } from "../Slices/activeKeySlice";

import { GetScreenSizes } from "../Components/ScreenSizes";
import { useMsal } from "@azure/msal-react";
import { Result, Spin } from "antd";
import ManualCheckInOut from "../Components/ManualCheckInOut/ManualCheckInOut";
import PendingLeaveApprovalsPage from "./PendingLeaveApprovalsPage";

//Lazy Loading
const DescriptionTable = lazy(() =>
  import("../Components/Approval/DescriptionTable")
);
const InOutDescriptionTable = lazy(() =>
  import("../Components/InOutMovement/InOutDescriptionTable")
);
const ApprovalTable = lazy(() =>
  import("../Components/Approval/ApprovalTable")
);
const InOutTable = lazy(() => import("../Components/InOutMovement/InOutTable"));
const PastLeaveApprovalTable = lazy(() =>
  import("../Components/PastLeaveApprovals/PastLeaveApprovalTable")
);
const AdminPage = lazy(() => import("./AdminPage"));
const DashBoard = lazy(() => import("../Components/Statistics/DashBoard"));

const StyledNavLink = styled(Nav.Link)`
  @media (max-width: 500px) {
    display: none; /* Hide on screens smaller than MD */
  }

  @media (min-width: 701px) and (max-width: 1199.98px) {
    display: block; /* Show on MD screens */
    align-self: flex-end;
    border: 2px solid red;
    border-radius: 8px;
    padding: 8px;
  }

  @media (min-width: 1200px) {
    display: block;
    align-self: flex-end;
    border: 2px solid red;
    border-radius: 8px;
    padding: 8px;
  }
`;

const StyledTabs = styled(Tabs)`
  .nav-link {
    border: none;
    padding: 10px 20px;
    transition: border-bottom-color 0.3s ease, color 0.3s ease;
    color: black;
  }

  .nav-link.active {
    color: #e31138;
    border-bottom: 2px solid #e31138;
  }
`;

const globalColor = "#e31138";

const ApprovalPage = (props) => {
  const state = useSelector((state) => state);
  const userRole = state.auth.userInfo.role;
  const keyword = state.search.keyword || "";
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSmallScreen, isMediumScreen, isLargeScreen } = GetScreenSizes();
  const [ids, setids] = useState("");
  const [records, setRecords] = useState("");
  const [dt, setDt] = useState("");
  const AdminOrWarden = userRole === "Admin" || userRole === "Warden";
  const OnlyAdmin = userRole === "Admin";
  const AdminOrWardenOrSecurity =
    userRole === "Admin" || userRole === "Warden" || userRole === "Security";
  const [key, setKey] = useState(AdminOrWarden ? "pending" : "inoutmovement");
  const [page, setPage] = useState(1);
  const [actionOutComeVals, setActionOutComeVals] = useState({
    htno: null,
    asnDate: null,
    token: null,
  });

  const {
    data: AppliedLeavesData,
    isError: isAppliedLeavesError,
    refetch,
  } = useFetchAppliedLeavesQuery({ keyword, page });

  const { data: StudentData, isError: isStudentDataError } =
    useFetchStudentByIdQuery(ids);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  let x;
  const { instance } = useMsal();

  async function handleClick() {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("hasLoggedInBefore");
    await instance.logoutRedirect();
    history.push("/admin-login");
    dispatch(logout());
  }

  const handchange = (value, rec, asn) => {
    setids(value);
    setRecords(rec);
    setDt(asn);
  };

  const actionOnLeave = ({ htno, asnDate, token }) => {
    setActionOutComeVals({ htno: htno, asnDate: asnDate, token: token });
  };

  const actionValuesSetterFromApprovalTable = () => {
    setActionOutComeVals({ htno: null, asnDate: null, token: null });
  };

  for (let val of AppliedLeavesData?.applied_leaves || []) {
    if (val.HTNO === ids && val.TOKEN === records && val.ASN_DATE === dt) {
      x = val;
    }
  }

  const pageSetterFunction = (pageNumber) => {
    setPage(pageNumber);
  };

  const desc = !x ? null : (
    <>
      {x && (
        <DescriptionTable
          triggerFunction={actionOnLeave}
          data={x}
          studentEmail={StudentData?.user.STUDENT_EMAIL}
          val={ids}
        />
      )}
    </>
  );

  const inoutdesc = (
    <>
      {StudentData && (
        <InOutDescriptionTable data={ids} student={StudentData?.user || []} />
      )}
    </>
  );

  const onChangeKey = (key) => {
    dispatch(setActiveKey(key));
    setKey(key);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const handleShowOffcanvas = () => {
    setShowOffcanvas(true);
  };

  const LoadingFallback = () => (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin spinning={true} size="large" colorPrimary={globalColor} />
    </div>
  );

  return (
    <div style={{ width: "100%" }}>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container
            fluid
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Navbar.Brand href="#home">
              <img
                alt="logo"
                src={mu}
                width={160}
                height={50}
                style={{ marginLeft: 20 }}
              />
            </Navbar.Brand>
            {isSmallScreen && (
              <Navbar.Toggle
                onClick={handleShowOffcanvas}
                className="d-md-none "
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
            )}
            {isMediumScreen && !isLargeScreen && (
              <button
                style={{
                  border: " 2px solid red",
                  borderRadius: "8px",
                  padding: "8px",
                  alignSelf: "flex-end",
                }}
                onClick={handleClick}
              >
                Sign Out
              </button>
            )}
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              show={showOffcanvas}
              onHide={handleCloseOffcanvas}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  MU
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav style={{ width: "100%" }} className="flex-column">
                  {AdminOrWarden && (
                    <Nav.Link
                      className="d-md-none"
                      onClick={() => {
                        onChangeKey("pending");
                        handleCloseOffcanvas();
                      }}
                    >
                      Pending Leave Approvals
                    </Nav.Link>
                  )}
                  {AdminOrWardenOrSecurity && (
                    <Nav.Link
                      className="d-md-none d-lg-none"
                      onClick={() => {
                        onChangeKey("inoutmovement");
                        handleCloseOffcanvas();
                      }}
                    >
                      In Out Movement
                    </Nav.Link>
                  )}
                  {/* {AdminOrWardenOrSecurity && (
                    <Nav.Link
                      className="d-md-none d-lg-none"
                      onClick={() => {
                        onChangeKey("visitors");
                        handleCloseOffcanvas();
                      }}
                    >
                      Visitor Pass Approvals
                    </Nav.Link>
                  )} */}
                  {AdminOrWarden && (
                    <Nav.Link
                      className="d-md-none d-lg-none"
                      onClick={() => {
                        onChangeKey("past");
                        handleCloseOffcanvas();
                      }}
                    >
                      Past Leave Approvals
                    </Nav.Link>
                  )}
                  {AdminOrWarden && (
                    <Nav.Link
                      className="d-md-none d-lg-none"
                      onClick={() => {
                        onChangeKey("hostelManagement");
                        handleCloseOffcanvas();
                      }}
                    >
                      Hostel Management
                    </Nav.Link>
                  )}
                  {OnlyAdmin && (
                    <Nav.Link
                      className="d-md-none d-lg-none"
                      onClick={() => {
                        onChangeKey("adminTab");
                        handleCloseOffcanvas();
                      }}
                    >
                      Admin
                    </Nav.Link>
                  )}
                  {isLargeScreen && (
                    <StyledNavLink
                      className="d-md-none d-lg-block"
                      onClick={handleClick}
                    >
                      Sign Out
                    </StyledNavLink>
                  )}
                  {isSmallScreen && (
                    <Nav.Link onClick={handleClick}>Sign Out</Nav.Link>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <StyledTabs
        activeKey={key}
        onSelect={(key) => onChangeKey(key)}
        id="uncontrolled-tab-example"
        className="mb-3 d-none d-sm-flex flex-direction-row"
      >
        {AdminOrWarden && (
          <Tab eventKey="pending" title=" Pending Leave Approvals">
            <Suspense fallback={<LoadingFallback />}>
              {desc}
              {isStudentDataError && isAppliedLeavesError && (
                <div
                  style={{
                    height: "80vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                  />
                </div>
              )}
              <PendingLeaveApprovalsPage tab={key} />
              {/* {AppliedLeavesData && (
                <ApprovalTable
                  outComeValues={actionOutComeVals}
                  change={handchange}
                  triggerFunction={actionOnLeave}
                  setActionsToNull={actionValuesSetterFromApprovalTable}
                  pageSetterFunction={pageSetterFunction}
                  data={x}
                  tab={key}
                />
              )} */}
            </Suspense>
          </Tab>
        )}
        {AdminOrWardenOrSecurity && (
          <Tab eventKey="inoutmovement" title=" In Out Movement">
            <Suspense fallback={<LoadingFallback />}>
              {inoutdesc}
              <InOutTable tab={key} change={handchange} />
            </Suspense>
          </Tab>
        )}
        {/* {AdminOrWardenOrSecurity && (
          <Tab eventKey="checkinout" title=" Check In/Out">
            <Suspense fallback={<LoadingFallback />}>
              {inoutdesc}
              <ManualCheckInOut tab={key} change={handchange} />
            </Suspense>
          </Tab>
        )} */}
        {/* {AdminOrWardenOrSecurity && (
          <Tab eventKey="visitors" title=" Visitor Pass Approvals"></Tab>
        )} */}
        {AdminOrWarden && (
          <Tab eventKey="past" title="Past Leave Approvals">
            <Suspense fallback={<LoadingFallback />}>
              <PastLeaveApprovalTable />
            </Suspense>
          </Tab>
        )}
        {isLargeScreen && AdminOrWarden && (
          <Tab eventKey="stats" title="Statistics">
            <Suspense fallback={<LoadingFallback />}>
              {" "}
              <DashBoard />
            </Suspense>
          </Tab>
        )}
        {AdminOrWarden && (
          <Tab eventKey="hostelManagement" title="Hostel Management">
            <Suspense fallback={<LoadingFallback />}>
              {" "}
              <AdminPage isWarden={true} />
            </Suspense>
          </Tab>
        )}
        {OnlyAdmin && (
          <Tab eventKey="adminTab" title="Admin">
            <Suspense fallback={<LoadingFallback />}>
              {" "}
              <AdminPage />{" "}
            </Suspense>
          </Tab>
        )}
      </StyledTabs>
    </div>
  );
};

export default ApprovalPage;
